import React from "react"
import { graphql } from "gatsby"
import Dialog from "../components/Modals/Dialog"
import SEO from "../components/seo"

import TheaterVideo from "@components/Theater/TheaterVideo"
import Layout from "../components/layout"
import { Heading } from "../components/Hero"
import { Section, Columns } from "../components/Containers"
import { CallToAction } from "../components/CTA"
import { Image, Text } from "../components/Core"
import AnesthesiaModal from "../components/Modals/AnesthesiaModal"
import SingleProcedureHero from "../components/SingleProcedureHero"

class AnesthesiaOptionsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dialogIsOpen: false,
      componentShownInDialog: {}
    }
    this.toggleDialog = this.toggleDialog.bind(this)
  }

  componentDidUpdate(prevState) {
    if (
      this.state.dialogIsOpen !== prevState.dialogIsOpen &&
      this.state.dialogIsOpen
    ) {
      document.documentElement.style.overflowY = "hidden"
    } else {
      document.documentElement.style.overflowY = "scroll"
    }
  }

  toggleDialog(component) {
    this.setState(prevState => ({
      dialogIsOpen: !prevState.dialogIsOpen,
      componentShownInDialog: component
    }))
  }

  render() {
    const language = this.props.pageContext.language
    const post = this.props.data.allUniquePagesJson.nodes[0]
    let cta = this.props.data.cta.nodes[0].getStarted
    if (language === "es") {
      cta = this.props.data.cta.nodes[0].getStartedSpanish
    }

    return (
      <Layout navSpacer pageTitle="an-options" language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={this.props.location.pathname}
          lang={language}
        />

        <Dialog
          isOpen={this.state.dialogIsOpen}
          onClose={this.toggleDialog}
          className="anesthesia-dialog">
          {this.state.componentShownInDialog}
        </Dialog>

        <SingleProcedureHero
          hero={post.hero}
          heading={post.heading}
          language={language}
          youtube={post.youtube}
        />

        {/* {!post.imageId ? ( */}
        {/* <Heading heading={post.heading} blurb={post.topBlurbs} /> */}
        {/* ) : (
          <div className="an-options__hero nav-spacer">
            <Image
              useAR
              publicId={post.imageId}
              className="procedure-hero-img"
            />

            <Section smallSpacing>
              <Columns sideColumnsSize={3}>
                <div className="column">
                  <Text as="h1" className="mb-0" text={post.heading} />
                  {post.subHeading && (
                    <Text as="h2" className="mb-0" text={post.subHeading} />
                  )}
                </div>
                {post.youtube && (
                  <>
                    <div className="column is-1"></div>
                    <div className="column is-narrow">
                      <TheaterVideo
                        language="en"
                        videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                        controls
                        playing
                        hasWatchVideo
                        buttonClass="contained mt-0 mt-2--mobile mx-auto--mobile"
                      />
                    </div>{" "}
                  </>
                )}
              </Columns>
            </Section>
          </div>
        )} */}

        {post.bodySections.map((sect, i) => {
          if (sect.type === "text") {
            return (
              <Section key={i} className="anesthesia-body-section">
                <Columns sideColumnsSize={5}>
                  <div className="column  px-40--mobile">
                    <Text useStringReplace text={sect.string} />
                  </div>
                </Columns>
              </Section>
            )
          }

          if (sect.type === "anesthesiaModal") {
            return (
              <Section key={i}>
                <Columns className="has-text-centered">
                  <div className="column">
                    <AnesthesiaModal clearBlurbLink />
                  </div>
                </Columns>
              </Section>
            )
          }

          if (sect.type === "anesthesiaText") {
            return (
              <Section key={i} smallSpacing>
                <Columns sideColumnsSize={5}>
                  <div className="column px-40--mobile">
                    <Text
                      className="mt-0"
                      as="h3"
                      text={post.anesthesiaTypes.heading}
                    />
                    <Text
                      className="mt-0"
                      as="p"
                      text={post.anesthesiaTypes.noInlinkBlurb}
                    />
                    {post.anesthesiaTypes.buttons.map(ant => (
                      <div key={ant.heading}>
                        <Text className="mt-1" as="h4" text={ant.heading} />
                        <Text text={ant.blurb} />
                      </div>
                    ))}
                  </div>
                </Columns>
              </Section>
            )
          }
        })}

        <CallToAction
          colorBack
          heading={cta.heading}
          blurb={cta.blurb}
          buttons={cta.buttons}
        />
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query anesthesiaOptionsPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        hero {
          image
          imageMobile
          animationStyle
          position
          size
          className
        }
        anesthesiaTypes {
          showButtons
          buttons {
            buttonText
            heading
            blurb
            youtube
            href
          }
          blurb
          noInlinkBlurb
          heading
        }
        youtube
        imageId
        bodySections {
          type
          string
        }
        getStarted {
          blurb
          buttonText
          heading
          href
          subheading
          buttons {
            button {
              appearance
              destination
              buttonText
              href
            }
          }
        }
        heading
        subHeading
        title
      }
    }
    cta: allTemplateSettingsJson(
      filter: { templateType: { eq: "single-procedure" } }
    ) {
      nodes {
        getStarted {
          blurb
          buttonText
          heading
          href
          subheading
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
        }
        getStartedSpanish {
          blurb
          buttonText
          heading
          href
          subheading
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
        }
      }
    }
  }
`

export default AnesthesiaOptionsPage

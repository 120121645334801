import React from "react"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { Button } from "../components/Button"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { CallToAction } from "../components/CTA"
import SingleProcedureHero from "../components/SingleProcedureHero"
import { Text, Image } from "../components/Core"
import { Columns, Section } from "../components/Containers"

const iCatPage = ({ data, pageContext, location }) => {
  const post = data.allUniquePagesJson.nodes[0]

  let cta = data.cta.nodes[0].getStarted
  if (pageContext.language === "es") {
    cta = data.cta.nodes[0].getStartedSpanish
  }

  return (
    <Layout navSpacer pageTitle="i-cat-page" language={pageContext.language}>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
        lang={pageContext.language}
      />

      <SingleProcedureHero
        hero={post.hero}
        heading={post.heading}
        language={pageContext.language}
        youtube={post.youtube}
      />

      {post.bodySections.map((sect, i) => {
        if (sect.type === "text") {
          return (
            <Section key={i} smallSpacing>
              <Columns sideColumnsSize={3}>
                <div className="column px-40--mobile">
                  <Text useStringReplace text={sect.string} />
                </div>
              </Columns>
            </Section>
          )
        }

        if (sect.type === "video") {
          return (
            <Section key={i} smallSpacing>
              <Columns sideColumnsSize={3}>
                <div className="column">
                  <TheaterVideo
                    buttonClass="contained"
                    videoUrl={`https://www.youtube.com/watch?v=${sect.youtube}`}
                    controls
                    playing
                    overVideo
                    language={pageContext.language}>
                    <Image publicId={sect.image} />
                  </TheaterVideo>
                </div>
              </Columns>
            </Section>
          )
        }
      })}

      <CallToAction
        colorBack
        heading={cta.heading}
        blurb={cta.blurb}
        buttons={cta.buttons}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query threeDImagingPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        hero {
          image
          imageMobile
          animationStyle
          position
          size
          className
        }
        heading
        metaTitle
        metaDescription
        youtube
        imageId
        bodySections {
          type
          string
          # youtube
          # image
        }
        getStarted {
          blurb
          buttonText
          heading
          href
          buttons {
            button {
              appearance
              destination
              href
              buttonText
            }
          }
        }
      }
    }
    cta: allTemplateSettingsJson(
      filter: { templateType: { eq: "single-procedure" } }
    ) {
      nodes {
        getStarted {
          blurb
          buttonText
          heading
          href
          subheading
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
        }
        getStartedSpanish {
          blurb
          buttonText
          heading
          href
          subheading
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
        }
      }
    }
  }
`

export default iCatPage

import React from "react"

const Youtube = ({ code }) => {
  const styles = {
    container: {
      position: "relative",
      paddingTop: "56.25%"
    },
    iframe: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    }
  }

  return (
    <div style={styles.container} className="yt__video-container">
      <iframe
        style={styles.iframe}
        src={`https://www.youtube.com/embed/${code}?modestbranding=1&rel=0&cc_load_policy=1`}
        allow="accelerometer; autoplay; encrypted-media; gyroscope;"
        allowFullScreen></iframe>
    </div>
  )
}

export default Youtube

import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { Section } from "../components/Containers"
import SEO from "../components/seo"
import ExternalScriptLoader from "../components/ExternalScriptLoader"

const PaymentPlans = ({ pageContext }) => {
  const { language } = pageContext

  return (
    <Layout language={language} navSpacer>
      <SEO
        lang={language}
        title="Payment Plans"
        description="Payment Plans at 7x7 Dental Implant & Oral Surgery Specialists."
      />
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Playfair+Display&family=Slabo+27px&family=Lato&family=Raleway&family=Montserrat&family=Oswald&family=Poppins&family=Source+Sans+Pro&family=PT+Sans&family=Open+Sans&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Section>
        <div className="payment-plans" style={{ marginTop: "150px" }}>
          <h1 className="has-text-centered px-40--mobile mb-3">
            Payment Plans With Cherry
          </h1>
          <ExternalScriptLoader src="/cherry.js">
            <div id="all"></div>
          </ExternalScriptLoader>
        </div>
      </Section>
    </Layout>
  )
}

export default PaymentPlans

import React from "react"

import { Text, Image } from "../../components/Core"
import { ButtonGroupMap } from "../../components/Button"
import { Columns, Section } from "../../components/Containers"

function InTheCommunity({
  heading,
  blurb,
  image,
  buttons,
  colorBack,
  useImages
}) {
  return (
    <Section colorBack={colorBack} className={`first-time`}>
      <Columns sideColumnsSize={useImages ? 2 : 4}>
        <div className="column">
          <div className={`${useImages ? "first-time__grid" : ""}`}>
            <div className="first-time__card">
              <Text
                as="h2"
                className={`mt-0 px-40--mobile`}
                style={{ marginBottom: "16px" }}
                text={heading}
              />
              <Text as="p" className="px-40--mobile" text={blurb} />
              <ButtonGroupMap
                buttons={buttons}
                isCentered={!useImages}
                isCenteredMobile={useImages}
                // noReverse={noReverse}
              />
            </div>
            {useImages && image && (
              <div className="first-time__image px-40--mobile">
                <Image publicId={image} />
              </div>
            )}
          </div>
        </div>
      </Columns>
    </Section>
  )
}

export default InTheCommunity

import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Seamless from "../components/Seamless"
import "../css/pages/academicAdvantage.scss"
import Youtube from "../components/Youtube"

const academicadvantage = () => {
  return (
    <Layout pageTitle="procedure-page" language={"en"}>
      <SEO
        lang={"en"}
        title="Learn about our Academic Advantage program"
        description="Learn about our Academic Advantage program"
      />

      <div className="edu-23">
        <div className="edu-23__section">
          <div className="edu-23__container">
            <img
              src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/2023-edu-desktop-hero-logo"
              alt="academic advantage logo"
              className="edu-23__hero--desktop"
            />
            <img
              src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/2023-edu-mobile-hero-logo"
              alt="academic advantage logo"
              className="edu-23__hero--mobile"
            />
          </div>
        </div>

        <div className="edu-23__section">
          <div className="edu-23__container">
            <Youtube code="Q9eXQZqeNVM" />
          </div>
        </div>

        <div className="edu-23__section">
          <div className="edu-23__container">
            <div className="edu-23__even-grid">
              <div>
                <h1>Let's Talk About Your Future</h1>
                <p>
                  At 7x7 Oral Surgery, we proudly provide top-quality
                  services—ranging from wisdom teeth removal to dental
                  implants—for San Francisco residents. We're equally committed
                  to supporting the next generation of healthcare professionals.
                </p>
                <p>Here’s What You’ll Learn:</p>
                <ul>
                  <li>
                    When and how to start planning for your healthcare career
                  </li>
                  <li>Tips on preparing for college and medical school</li>
                  <li>The daily routine of a medical professional</li>
                  <li>What you can expect from a medical career</li>
                  <li>What it’s like to be an entrepreneur and a doctor</li>
                  <li>The many healthcare career paths you can take</li>
                </ul>
                <p>
                  In addition to learning from talented and respected medical
                  professionals, representatives from local colleges and
                  nonprofits will be available to provide more information about
                  college admissions, specific topics, and answer questions.
                </p>
              </div>
              <div>
                <div className="edu-23__date-time">
                  <p>
                    <span className="edu-23__blue bold">Spring 2025</span>
                  </p>

                  <p>
                    <span className="edu-23__blue bold">
                      San Francisco Exploratorium: Pier 15
                    </span>
                    <br />
                    Embarcadero at Green Street
                    <br />
                    San Francisco, CA 94111
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="edu-23__section">
          <div className="edu-23__container">
            <div className="edu-23__speakers">
              <h2>Meet Our Esteemed Speakers & Expo Partners:</h2>
              <p>
                We are excited to welcome a diverse group of healthcare
                professionals and organizations who will share their insights
                and experiences:
              </p>
              <div className="edu-23__even-grid">
                <p>
                  <span className="bold">Dr. Eric Scharf</span>
                  <span style={{ display: "block", fontWeight: "500" }}>
                    Founder & Head Doctor of 7x7 Oral Surgery
                  </span>
                  <span style={{ display: "block", marginTop: "10px" }}>
                    As a renowned oral surgeon and proud sponsor of Academic
                    Advantage, Dr. Scharf will share his professional journey
                    and lead students through engaging breakout sessions.
                  </span>
                </p>
                <p>
                  <span className="bold">Michael Fuller</span>
                  <span style={{ display: "block", fontWeight: "500" }}>
                    Adjunct Professor, Skyline College Biotech Pathway
                  </span>
                  <span style={{ display: "block", marginTop: "10px" }}>
                    An expert in biotechnology, Michael brings valuable
                    knowledge on the emerging biotech field and its career
                    opportunities.
                  </span>
                </p>
                <p>
                  <span className="bold">Ambrosia Fisher</span>
                  <span style={{ display: "block", fontWeight: "500" }}>
                    Director of Pediatric Nursing
                  </span>
                  <span style={{ display: "block", marginTop: "10px" }}>
                    With a wealth of experience in pediatric care, Ambrosia will
                    discuss the critical role of nurses in supporting children's
                    health and development.
                  </span>
                </p>
                <p>
                  <span className="bold">Sherman Auyeung</span>
                  <span style={{ display: "block", fontWeight: "500" }}>
                    Director of Rehabilitation Services
                  </span>
                  <span style={{ display: "block", marginTop: "10px" }}>
                    Sherman leads rehabilitation services and will offer
                    insights into the growing demand for rehabilitative
                    healthcare professionals.
                  </span>
                </p>
                <p>
                  <span className="bold">Kristine Medina</span>
                  <span style={{ display: "block", fontWeight: "500" }}>
                    Director of Clinical Lab Services
                  </span>
                  <span style={{ display: "block", marginTop: "10px" }}>
                    Kristine manages clinical lab operations and will speak on
                    the importance of lab work in diagnostics and patient care.
                  </span>
                </p>
                <p>
                  <span className="bold">Victoria Adekota</span>
                  <span style={{ display: "block", fontWeight: "500" }}>
                    Expert in Clinical Data Management
                  </span>
                  <span style={{ display: "block", marginTop: "10px" }}>
                    Victoria specializes in managing healthcare data, a crucial
                    aspect of modern medicine, and will explain how this field
                    shapes patient outcomes.
                  </span>
                </p>
                <p>
                  <span className="bold">
                    Carmen Calderon, Kathrine Aquino, & James
                  </span>
                  <span style={{ display: "block", fontWeight: "500" }}>
                    Northern California Nursing Academy
                  </span>
                  <span style={{ display: "block", marginTop: "10px" }}>
                    As representatives of the Northern California Nursing
                    Academy, Carmen, Kathrine, and James will explore the vital
                    role of nurses in healthcare, discussing various nursing
                    specialties and the pathways to becoming a skilled nursing
                    professional.
                  </span>
                </p>
              </div>
            </div>
            <p style={{ marginTop: "40px" }}>
              In addition, our expo partners include:
            </p>
            <ul>
              <li className="bold">UCSF School of Physical Therapy</li>
              <li className="bold">UCSF School of Clinical Lab Sciences</li>
              <li className="bold">City College of San Francisco</li>
              <li className="bold">Stanford Medical Youth Science</li>
              <li className="bold">Certified Nursing Assistant Training</li>
              <li className="bold">Special Olympics of Northern California</li>
              <li className="bold">UCSF School of Nursing</li>
              <li className="bold">University of the Pacific</li>
            </ul>
            <p>
              Both our speaker lineup and expo partner list are continually
              growing, with more healthcare professionals and organizations to
              be announced soon. Stay tuned for updates!
            </p>
          </div>
        </div>

        <div className="edu-23__apply">
          <h3>APPLY FOR OUR $1,000 SCHOLARSHIP</h3>
        </div>

        <div className="edu-23__form">
          <Seamless src="https://secureform.seamlessdocs.com/f/eae0b16c10b9c22a74e8760feed07fe3?embedded=true" />
        </div> */}
      </div>
    </Layout>
  )
}

export default academicadvantage
